import React, {useContext} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Pager from "../components/navigation/pager";
import {SiteContext} from "../context/site-context";
import "./articles.scss"
import ArticleList from "../components/node/article-list";

const Articles = ({ data, pageContext }) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  return (
    <Layout language={pageContext.language}>
      <div className="blogs-overview ">
        <div className="inner-content">
          <h1>{i18next.t('Blogs')}</h1>
          {data.drupal.nodeQuery.entities.map((entity) => (
            <ArticleList
              key={entity.entityTranslation.entityId}
              entity={entity.entityTranslation}
            />
          ))}
        </div>
        <Pager {...pageContext}/>
      </div>
    </Layout>
  )
};

export default Articles;

export const query = graphql`
query($language: [String], $languageId: Drupal_LanguageId!, $skip: Int!, $limit: Int!) {
  drupal {
    nodeQuery(
      filter: {conditions: 
      [
        {field: "type", operator: EQUAL, value: "article"},
        {field: "langcode", operator: EQUAL, value: $language},
        {field: "status", operator: EQUAL, value: "1"}
      ]},
      limit: $limit,
      offset: $skip,
      sort: {field: "created", direction: DESC}) {
      entities {
        ... on Drupal_NodeArticle {
          entityTranslation(language: $languageId) {
             ...ArticleTeaser
          }
        }  
      }
    }
  }
}
`;
